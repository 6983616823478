

//──── ERPL SIDE SEARCH ──────────────────────────────────────────────────────────────────

.erpl_side-search {

	.erpl_side-search-main-search {
		border-bottom: 1px solid $border-color;

	}

	.erpl_criteria {
		border-bottom: 1px solid $border-color;

		&:empty {
			display: none !important;
		}

	}

	.erpl_side-search-criteria {
		.erpl_side-search-criteria-body {
			.erpl_side-search-criteria-item {
				border-bottom: 1px solid $border-color;
			}
		}
	}

	.erpl_side-search-criteria {

		&.animated {
			// animation-fill-mode: inherit;
			width: 100%;
			display: block;
			position: fixed;
			clear: both;
			z-index: 999;
			box-shadow: 0 0 40px 0px rgba(0,0,0,0.3);
			top: 0;
			bottom: 0;
			left: 0;
		}
	}


	&.mobile {

		.erpl_side-search-criteria {
			width: 100%;
			display: flex;
			flex-direction: column;
			position: fixed;
			z-index: 999;
			box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.3);
			top: 0;
			bottom: 0;
			left: 0;
			transform: translate3d(-100%,0 , 0 ); 
			opacity: 0;
			background: $white;
		
			&.active {
				transform: translate3d(0 ,0 , 0 ); 
				opacity: 1;
				transition: transform .5s
			}
		
			&.hidden {
				transition: .5s;	
			}
			
		
			&.on-touch {
				opacity: 1;
				transition: transform 0s !important
		
			}
		
		
			// Header 
			.erpl_side-search-criteria-header  {
				height: 40px;
				padding: 0 1.5rem;
			 }
			 
			// Body
			.erpl_side-search-criteria-body  {
				 
				padding: 1.5rem;
				flex-grow: 1;
				position: relative;
				overflow: auto;
		
				// Item
				.erpl_side-search-criteria-item {
		
					
		
					&.active {
						position: absolute;
						top: 0;
						padding: 1.5rem;
						margin: 0 !important;
						border: none;
						left: 0;
						right: 0;
						bottom: 0;
						background: white;
						z-index: 999;
						overflow: auto;
					}
		
					// Item Content
					.erpl_side-search-criteria-item-content {
						
						.erpl_dropdown-custom[data-max-height=auto] .erpl_dropdown-content>* {
							max-height: none;
						}
				
		
						// Select 2 
						.select2.select2-container {
							width: 100% !important;
						}
		
						// Form Check
						.form-check {
							padding-left: 0;
		
							input {
								display: none;
							}
		
							input + span {
								content: "";
								display: block; 
								background: $white; 
								height: 21px;
								width: 21px; 
								border: 1px solid $border-color;
								display: flex;
								align-items: center;
								justify-content: center
							}
		
							input:checked + span:after {
								content: "";
								display: block; 
								height: 11px;
								width: 11px; 
								background: $primary; 
							}
		
							input:disabled + span:after {
								content: "";
								display: block; 
								height: 11px;
								width: 11px; 
								background: $border-color; 
							}
		
							input[type="radio"] + span {
								border-radius: 50%
							}
		
							input[type="radio"] + span:after {
								border-radius: 50%
							}	
		
							label {
								display: block;
								border-bottom: 1px dotted $border-color;
								padding: 1.25rem 0;
								display: flex;
								justify-content: space-between;
								align-items: center
							}
							
							
						}
						// .form-check:last-child label {
						// 	border: none; 
						// }
		
					}
		
 
				}
			
			}
		
			// Footer
			.erpl_side-search-criteria-footer  {
				padding: 0 1.5rem;
				align-items: center;
				 display: flex;
				border-top: 1px solid $border-color;
				height: 80px;
			}
		
		
		}


	}

	&:not(.mobile) {

		.erpl_side-search-criteria .erpl_side-search-criteria-body .erpl_side-search-criteria-item {
			.erpl_side-search-criteria-item-content .erpl_side-search-criteria-item-title {
				display: none;
			}
			> .erpl_side-search-criteria-item-title .erpl_side-search-criteria-item-value  {
				display: none;
			}
		}



	}
}

 