

//========================================================================================
/*                                                                                      *
 *                                    ERPL SCROLLBAR                                    *
 *                                                                                      */
//========================================================================================
.erpl_scrollbar {
    
	.scrollbar {
		background: rgba($secondary, 0.1);
		position: relative;
		min-width: 5px;
		right: 0;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;

		.track {
			width: 5px;
			background: rgba($secondary, 0.2);
			position: relative;
		}
	}

	&:hover {
		.scrollbar {
			.track {
				background: rgba($secondary, 0.5);
			}
		}
	}		
	
	
	&[data-theme="dark"] {
		.scrollbar {
		background: rgba($dark, 0.3);
		/*height: 100%;*/
		min-width: 5px;
		right: 0;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;

		.track {
			width: 5px;
			background: rgba($dark, 0.5);
			position: relative;
		}
	}

		&:hover {
			.scrollbar {
				.track {
					background: rgba($dark, 0.8);
				}
			}
		}	
	}

	&[data-theme="danger"] {
		.scrollbar {
		background: rgba($danger, 0.1);
		/*height: 100%;*/
		min-width: 5px;
		right: 0;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;

		.track {
			width: 5px;
			background: rgba($danger, 0.5);
			position: relative;
		}
	}

		&:hover {
			.scrollbar {
				.track {
					background: rgba($danger, 0.8);
				}
			}
		}	
	}

	&[data-theme="light"] {
		.scrollbar {
			background: rgba(0, 0, 0, 0.05);
			/*height: 100%;*/
			min-width: 5px;
			right: 0;
			display: flex;
			justify-content: center;
			top: 0;
			bottom: 0;

			.track {
				width: 5px;
				background: rgba(255, 255, 255, 0.2);
				position: relative;
			}
		}

		&:hover {
			.scrollbar {
				.track {
					background: rgba(255, 255, 255, 0.5);
				}
			}
		}
	}

	&[data-theme="primary"] {
		.scrollbar {
		background: rgba($primary, 0.1);
		/*height: 100%;*/
		min-width: 5px;
		right: 0;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;

		.track {
			width: 5px;
			background: rgba($primary, 0.5);
			position: relative;
		}
	}

		&:hover {
			.scrollbar {
				.track {
					background: rgba($primary, 0.8);
				}
			}
		}	
	}

	&[data-theme="success"] {
		.scrollbar {
		background: rgba($success, 0.1);
		/*height: 100%;*/
		min-width: 5px;
		right: 0;
		display: flex;
		justify-content: center;
		top: 0;
		bottom: 0;

		.track {
			width: 5px;
			background: rgba($success, 0.5);
			position: relative;
		}
	}

		&:hover {
			.scrollbar {
				.track {
					background: rgba($success, 0.8);
				}
			}
		}	
	}

	&[data-width="large"] {
		.scrollbar {
			min-width: 14px;

			.track {
				width: 100%;
			}
		}
	}

	&[data-round="true"] {
		.scrollbar {
			border-radius: 50px;

			.track {
				border-radius: 50px;
			}
		}
	}

	&[data-padding="true"] {
		.scrollbar {
			padding: 3px
		}
	}

	&[data-shadow="true"] {
		.scrollbar {
			box-shadow: 0 0 10px inset rgba(0,0,0,.1);
			overflow: hidden;

			.track {
				box-shadow: 0 0 10px rgba(0,0,0,.2);
			}
		}
	}
}