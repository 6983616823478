
.erpl_split-screen {
    height: calc(100vh - 40px);
    overflow: hidden;

    .erpl_split-screen-window {
        overflow-y: scroll;
        height: 30%;

        &:first-child {
            height: 70%;
            position: relative;
        }

    }

    .erpl_split-screen-window-size {
                background: $white;
                cursor: ns-resize;
                z-index: 1;
                text-align: center;
                font-size: 5px;
                border-top: 2px solid $gray-400;
                border-bottom: 2px solid $gray-400;
                height: 6px;
                position: relative;

                i {
                    display: none;
                }
    }


}

