//──── ERPL CONTACT CARD ─────────────────────────────────────────────────────────────────
.erpl_contact-card .erpl_contact-card-list ul {
    list-style-type: none;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.erpl_contact-card .erpl_contact-card-list ul li {
    display: flex;
    align-items: center;
    position: relative;
    line-height: normal;
}

.erpl_contact-card .erpl_contact-card-list ul a {
    overflow: hidden;
    width: 100%
}

.erpl_contact-card .erpl_contact-card-list ul a>div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.erpl_contact-card .erpl_contact-card-list ul i {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}