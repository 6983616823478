    //──── Produits ──────────────────────────────────────────────────────────────────────────
    .erpl_product {
        position: relative;
        &:hover {
            .erpl_product-badge {
                background: $white;
                color: $primary;
            }
        }
        .erpl_product-badge {
            background: $primary;
            color: $white;
            position: absolute;
            margin-top: 10px;
            margin-left: 10px;
            z-index: 1;
            padding: 5px 10px 4px;
            text-transform: uppercase;
            font-size: .6875rem
        }
        .erpl_product-video-badge {
            background: $gray-900;
            color: $white;
            position: absolute;
            margin-right: 1px;
            margin-bottom: 1px;
            z-index: 1;
            padding: 10px 13px 11px;
            text-transform: uppercase;
            font-size: .6875rem;
            bottom: 0;
            right: 0;
        }
        .erpl_product-subtitle {
            vertical-align: middle;
            >span+span:not(.badges):before {
                content: " – ";
            }
            >span.badges span+span {
                margin-left: 5px;
            }
        }
        span+span.badges {
            margin-left: 5px;
        }
    }
    
    .erpl_product-intro {
        color: $gray-700;
    }
    
    .erpl_product ul {
        list-style-position: inside;
    }