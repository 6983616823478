

//──── ERPL TABLE REPSONSIVE ─────────────────────────────────────────────────────────────
.erpl_table-responsive[data-responsive="true"] {
	thead {
		display: none;
	}
	tbody {
		td, th {
			display: block;
			&[data-th]:before {
				content: attr(data-th);
				margin-right: 5px;
			}
		}

		tr:after {
			display: block;
			content: "";
			height: 2px; 
			background: $border-color;
		}
	}

}
@media (max-width: map-get($map: $grid-breakpoints, $key: md) - 1){
	.erpl_table-responsive:not([data-responsive-breakpoint]) {
		thead {
			display: none;
		}
		tbody {
			td, th {
				display: block;
				&[data-th]:before {
					content: attr(data-th);
					margin-right: 5px;
				}
			}

			tr:after {
				display: block;
				content: "";
				height: 2px; 
				background: $border-color;
			}
		}

	}
}