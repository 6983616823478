
//──── ERPL Member list ──────────────────────────────────────────────────────────────────
.erpl_member-list .erpl_image-frame {
	width: 80px;
	margin: auto; 
	border: 1px solid $border-color
}

.erpl_member-list .erpl_image-frame {
	width: 80px;
	margin: auto; 
	border: 1px solid $border-color
}

.erpl_member-list .erpl_member-list-item .erpl_member-list-item-content{
	position: relative;
	display: block;
	height: calc(100% - 30px);
}

.erpl_member-list .erpl_member-list-item .erpl_member-list-item-content:before {
  border-left: 1px dotted $border-color;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
}

.erpl_member-list .erpl_member-list-item .erpl_member-list-item-content .erpl_title-h4 {
	color: $secondary
}
.erpl_member-list .erpl_member-list-item:hover .erpl_member-list-item-content .erpl_title-h4 {
	color: $link-color
}


.erpl_member-list .erpl_member-list-item .erpl_member-list-item-content .erpl_title-h5 {
	color: $secondary
}
.erpl_member-list .erpl_member-list-item:hover .erpl_member-list-item-content .erpl_title-h5 {
	color: $primary
}



.erpl_member-list .erpl_member-list-item {
	position: static
}
// .erpl_member-list .erpl_member-list-item:nth-child(4n+1):after {

.erpl_member-list .erpl_member-list-item:not(:first-child):before {
	position: absolute;
    display: block;
    overflow: hidden;
    left: 30px;
    right: 30px;
    height: 0;
	border-top: 1px dotted #d1d3d4;
	margin-top: -30px;

}

.erpl_member-list .erpl_member-list-item a:focus {
  outline-color: white
}

.erpl_member-list .erpl_member-list-item a:focus > div {
  outline-style: inherit;
  outline-color: $primary
}

@media (min-width: map-get($map: $grid-breakpoints, $key: xs)) and (max-width: map-get($map: $grid-breakpoints, $key: sm)-1) {
	.erpl_member-list .erpl_member-list-item:not(:nth-child(2n+1) ) .erpl_member-list-item-content:before {
		content: "";
	}

 	.erpl_member-list .erpl_member-list-item:nth-child(2n-1):before {
		content: ""
	}

}

@media (min-width: map-get($map: $grid-breakpoints, $key: sm)) and (max-width: map-get($map: $grid-breakpoints, $key: md)-1) {
	.erpl_member-list .erpl_member-list-item:not(:nth-child(3n+1) ) .erpl_member-list-item-content:before {
		content: "";
	}
	.erpl_member-list .erpl_member-list-item:nth-child(3n-2):before {
		content: ""
	}	
}

@media (min-width: map-get($map: $grid-breakpoints, $key: md)) and (max-width: map-get($map: $grid-breakpoints, $key: lg)-1) {
	.erpl_member-list .erpl_member-list-item:not(:nth-child(4n+1) ) .erpl_member-list-item-content:before {
		content: "";
	}
	.erpl_member-list .erpl_member-list-item:nth-child(4n-3):before {
		content: ""
	}		
}


@media (min-width: map-get($map: $grid-breakpoints, $key: lg)) and (max-width: map-get($map: $grid-breakpoints, $key: xl)-1) {
	.erpl_member-list .erpl_member-list-item:not(:nth-child(3n+1) ) .erpl_member-list-item-content:before {
		content: "";
	}
	.erpl_member-list .erpl_member-list-item:nth-child(3n-2):before {
		content: ""
	}		
}

@media (min-width: map-get($map: $grid-breakpoints, $key: xl)) {
	.erpl_member-list .erpl_member-list-item:not(:nth-child(4n+1) ) .erpl_member-list-item-content:before {
		content: "";
	}
	.erpl_member-list .erpl_member-list-item:nth-child(4n-3):before {
		content: ""
	}		
}
