
//──── erpl_full-width-banner-section ────────────────────────────────────────────
.erpl_full-width-banner-section {
	position: relative
}

.erpl_full-width-banner-section[data-lightbox="true"] .erpl_image-frame:after {
	content: "";
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	background: rgba(0,0,0,.5);
	position: absolute
}

.erpl_full-width-banner-section:not([data-parallax="mode1"]) > .erpl_image-frame img {
	max-height: 338px
}

.erpl_full-width-banner-section:not([data-parallax="mode1"])[data-size="frame"] > .erpl_image-frame img {
	max-height: inherit
}

.erpl_full-width-banner-section .erpl_full-width-banner-section-content {
	position: absolute;
    top: 0;
    bottom: 0; 
    z-index: 9;
    left: 0;
    right: 0;
}

.erpl_full-width-banner-section .erpl_full-width-banner-section-content .container {
	height: 100%
}

.erpl_full-width-banner-section .erpl_full-width-banner-section-content .container > .row {
	height: 100%;
	align-items: center
}
	// Gestion parallax
.erpl_full-width-banner-section[data-parallax="mode1"] .erpl_image-frame {
	max-height: 338px;
	overflow: hidden;
}

.erpl_full-width-banner-section[data-parallax="mode1"][data-size="frame"] .erpl_image-frame {
	max-height: inherit;
}

.erpl_full-width-banner-section[data-parallax="mode1"] .erpl_image-frame span img {
	object-fit: unset;
	height: auto;
	// transition-delay: 00ms;
	// transition: 1ms
}

.erpl_full-width-banner-section[data-parallax="mode2"] .erpl_image-frame span img {
	display: none;
}

