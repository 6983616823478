//──── ERPL Image Frame ──────────────────────────────────────────────────────────────────
.erpl_image-frame {
	position: relative;
    line-height: 0;
}
.erpl_image-frame > img {
	width: 100%;
	height: auto;
}

.erpl_image-frame span {
	position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.erpl_image-frame span img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	// Fix IE avec Polyfill JS
	font-family: 'object-fit: cover;';
}
