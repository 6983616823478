.erpl_video, .erpl_video-custom {
    position: relative;
    border: 1px solid $border-color;
    overflow: hidden;
    &:not(.fullscreen) {
        padding-bottom: 62.5%;
        height: 0px;
    }

    &.fullscreen  {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed !important;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        video {
            object-fit: contain;
        }
    }
    
    video {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: fill;
        position: absolute!important;
    }
    .erpl_video-play-btn-container {
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        cursor: default!important;
        .erpl_video-play-btn {
            border: none;
            background: none !important;
            i {
                background: $gray-900;
                border-radius: 50%;
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0.9;
                &.erpl_icon.erpl_icon-play {
                    -webkit-transform: scale(2.5);
                    transform: scale(2.5);
                    &:after {
                        background-position: -31px -436px;
                        width: 14px;
                        height: 21px;
                    }
                }
                &.erpl_icon.erpl_icon-play:hover {
                    background-color: $primary!important;
                    border: 1px solid $white;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }

    .erpl_video-controls {

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        display: none;
        transition: .5s;
        transform: translateY(100%);

        button {
            opacity: .75;
            &:hover, &:focus {
                opacity: 1;
            }

            &:focus {
                box-shadow : 0px 0px 0px 3px $primary
            }
        }
        .erpl_video-controls-timeline {
            height: 1.5rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .progress {
                height: .35rem;
                width: 100%;
                background-color: rgba(255, 255, 255, .65);

                .progress-bar {
                    z-index: 2;
                    transition: none;
                }
                .progress-bar-hover {
                    display: none;
                    background-color: $white;
                    z-index: 1;
                    left: 0;
                    position: absolute;
                    height: .35rem;
                    transition: none;
                }
                .progress-bar-chip {
                    background: $white;
                    border-radius: 50%;
                    height: 1rem;
                    width: 1rem;
                    position: absolute;
                    top: 0.25rem;
                    z-index: 3;
                    pointer-events: none;
                }
            }
        }
        .erpl_video-controls-volume {
            button:hover, button:focus {
                .erpl_video-controls-volume-slider {
                    display: flex;
                    background: $gray-600;
                    width: 22px;
                    height: 100px;
                    bottom: 0;
                    top: -112px;
                    align-items: flex-end;
                    z-index: 3;
                    border: 6px solid $gray-900;
                    box-sizing: content-box;
                   
    
                    .erpl_video-controls-volume-slider-inner {
                        display: block;
                        width: 100%;
                        height: 25%;
                        background-color: $primary;
                    }
                }
            }
        }
        .erpl_video-controls-icon {
            display: inline-block;
            font-size: 0;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 32px;
            height: 32px;
            position: relative;

        }

        .erpl_video-controls-icon-play {
            > * { 
                position: absolute;
                left: 0;
                top: 0;
            }
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M256 287.843c0-35.259 28.407-63.843 64-63.843 35.346 0 64 28.564 64 63.843v448.314c0 35.259-28.407 63.843-64 63.843-35.346 0-64-28.564-64-63.843v-448.314zM640 287.843c0-35.259 28.407-63.843 64-63.843 35.346 0 64 28.564 64 63.843v448.314c0 35.259-28.407 63.843-64 63.843-35.346 0-64-28.564-64-63.843v-448.314z' /%3E%3C/svg%3E");
        }



        .erpl_video-controls-icon-volume {
             > * { 
                position: absolute;
                left: 0;
                top: 0;
            }
            .erpl_video-controls-icon-volume-base {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M128 416v192h96v-192h-96zM64 383.853c0-17.592 14.012-31.853 32.094-31.853h159.813c17.725 0 32.094 14.581 32.094 31.853v256.295c0 17.592-14.012 31.853-32.094 31.853h-159.813c-17.725 0-32.094-14.581-32.094-31.853v-256.295z' /%3E%3Cpath fill='%23fff' d='M288 634.342l160 88.889v-422.462l-160 88.889v244.684zM224 352l231.787-128.771c31.046-17.248 56.213-2.487 56.213 32.476v512.589c0 35.184-25.054 49.786-56.213 32.476l-231.787-128.771v-320z' /%3E%3C/svg%3E")
            }
            .erpl_video-controls-icon-volume-waves {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M802.017 837.177c82.359-86.627 129.183-201.774 129.183-324.26 0-123.976-47.976-240.409-132.127-327.329-12.293-12.697-32.552-13.025-45.249-0.732s-13.025 32.552-0.732 45.249c72.692 75.084 114.109 175.597 114.109 282.812 0 105.928-40.422 205.331-111.566 280.162-12.177 12.808-11.666 33.063 1.143 45.24s33.063 11.666 45.24-1.143z' /%3E%3Cpath fill='%23fff' d='M667.436 743.221c67.761-60.884 107.273-147.888 107.273-241.233 0-87.318-34.552-169.203-94.836-229.446-12.501-12.493-32.762-12.486-45.255 0.015s-12.486 32.762 0.015 45.255c48.375 48.342 76.075 113.989 76.075 184.176 0 75.021-31.679 144.776-86.048 193.627-13.146 11.812-14.227 32.044-2.416 45.19s32.044 14.227 45.19 2.416z' /%3E%3C/svg%3E");
            }
            .erpl_video-controls-icon-volume-mute {
                display: none;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M768 466.745l-67.986-67.986c-12.213-12.213-32.654-12.393-45.151 0.104-12.584 12.584-12.543 32.711-0.104 45.151l67.986 67.986-67.986 67.986c-12.213 12.213-12.393 32.654 0.104 45.151 12.584 12.584 32.711 12.543 45.151 0.104l67.986-67.986 67.986 67.986c12.213 12.213 32.654 12.393 45.151-0.104 12.584-12.584 12.543-32.711 0.104-45.151l-67.986-67.986 67.986-67.986c12.213-12.213 12.393-32.654-0.104-45.151-12.584-12.584-32.711-12.543-45.151-0.104l-67.986 67.986z' /%3E%3C/svg%3E")
            }

            
        }



        .erpl_video-controls-icon-fullscreen {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M800 269.255v114.745c0 17.673 14.327 32 32 32s32-14.327 32-32v-192c0-17.673-14.327-32-32-32h-192c-17.673 0-32 14.327-32 32s14.327 32 32 32h114.745l-137.373 137.373c-12.497 12.497-12.497 32.758 0 45.255s32.758 12.497 45.255 0l137.373-137.373z' /%3E %3Cpath fill='%23fff' d='M224 754.745v-114.745c0-17.673-14.327-32-32-32s-32 14.327-32 32v192c0 17.673 14.327 32 32 32h192c17.673 0 32-14.327 32-32s-14.327-32-32-32h-114.745l137.373-137.373c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0l-137.373 137.373z' /%3E%3C/svg%3E");
        }
    }

    // MODE 
    &.paused {
        .erpl_video-controls {
            .erpl_video-controls-icon-play{
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M796.806 461.202c44.919 28.075 44.739 73.706 0 101.668l-459.472 287.171c-44.919 28.075-81.334 7.915-81.334-45.305v-585.4c0-53.096 36.595-73.266 81.334-45.305l459.472 287.171z' /%3E%3C/svg%3E");

            }
        }

    }

    &.muted {
        .erpl_video-controls {
            .erpl_video-controls-icon-volume {
                .erpl_video-controls-icon-volume-mute {
                    display: block;
                }

                .erpl_video-controls-icon-volume-waves {
                    display: none;
                }

                .erpl_video-controls-volume-slider .erpl_video-controls-volume-slider-inner {
                    display: none !important;
                }
            }
        }
    }

    &.fullscreen {
        .erpl_video-controls {
            .erpl_video-controls-icon-fullscreen {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1024 1024' width='36' height='36'%3E%3Cpath fill='%23fff' d='M672 306.745v-114.745c0-17.673-14.327-32-32-32s-32 14.327-32 32v192c0 17.673 14.327 32 32 32h192c17.673 0 32-14.327 32-32s-14.327-32-32-32h-114.745l137.373-137.373c12.497-12.497 12.497-32.758 0-45.255s-32.758-12.497-45.255 0l-137.373 137.373z' /%3E%3Cpath fill='%23fff' d='M352 717.255v114.745c0 17.673 14.327 32 32 32s32-14.327 32-32v-192c0-17.673-14.327-32-32-32h-192c-17.673 0-32 14.327-32 32s14.327 32 32 32h114.745l-137.373 137.373c-12.497 12.497-12.497 32.758 0 45.255s32.758 12.497 45.255 0l137.373-137.373z' /%3E%3C/svg%3E")
            }
        }

    }

    &.timeline-hover {

        .erpl_video-controls {
            .erpl_video-controls-timeline {
                .progress {
                    .progress-bar-hover {
                        display: block;
                    }
                }
            }
        }
    }

    &.show-controls.played, &.end, & video:focus + {

        .erpl_video-controls {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &.played {
        .erpl_video-controls {
            display: block;

        }
    }
}