//──── ERPL CRITERIA ────────────────────────────────────────────────────────────────────
.erpl_criteria .btn-default {
	color: $primary;
	white-space: normal
}
.erpl_criteria .btn-default:hover {
	border-color: $primary;
	background: $primary; 
	color: $white
}

.erpl_criteria .btn-default > * {
	vertical-align: middle
}

.erpl_criteria .btn-default:hover i.erpl_icon-close-light:after {
	background-position: -90px -212px;
}