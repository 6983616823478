
//──── ERPL FORM VALIDATE ────────────────────────────────────────────────────────────────
.erpl_form-validation, .erpl_form-validation-custom {

 	.form-control.valid {
		border-color: $success
	}

	.form-control.valid:focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($success, .25)
	}

	label.valid {
		color: $success;
		width: 100%;
		margin-top: 0.25rem;
		font-size: 85.71429%;
	}
	

	.form-control.error {
		border-color: $danger !important;
	}

	.form-control.error:focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($danger, .25)
	}

	label.error {
		color: $danger;
		width: 100%;
		margin-top: 0.25rem;
		font-size: 85.71429%;
	}
	
}

.error-box label.error {
	background: $danger;
	color: $white; 
	z-index: 951;
	padding: .5rem 1rem;
	margin: 0;
	position: relative;
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	top: -3px; 
} 

.erpl_dropdown.error .value,
.erpl_dropdown.error .erpl_icon,
.erpl_dropdown.error .erpl_dropdown-content > div {
	border-color: $danger !important
}

.erpl_accordion ul .erpl_accordion-item.error {
	border : 1px solid $danger;
	position: relative;
	z-index: 9;
}

// -- Spécific Select2

select.form-control.select2 {
	&.valid + span.select2 .select2-selection {
		border-color: $success
	}

	&.error + span.select2 .select2-selection {
		border-color: $danger
	}	

}



.was-validated .select2.form-control {
	&:valid + span.select2 .select2-selection {
		border-color: $success
	}
	&:invalid + span.select2 .select2-selection {
	border-color: $danger
	}
}
// -- Placeholder : color

input::placeholder {
  color:$gray-500 !important;

}