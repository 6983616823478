.erpl_context-menu {
	&:before {
		background: transparent;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		content: "";
	}

	a {
		color: $white;
		text-decoration: none;
		&:hover {
			color: $white
		}
	}

	ul {
		background: $gray-800;
		color: $white;
		border-radius: $border-radius;
		box-shadow : -0.2rem 0.2rem 0.8rem -0.2rem rgba(0, 0, 0, .35);
		

		padding: 0;
		margin: 0;
		list-style: none;
	}

	> ul {


		li {
			background: $gray-800;
			position: relative;
			padding:  $btn-padding-y $btn-padding-x ;
			cursor: pointer;

			&:first-child {
				border-radius: $border-radius $border-radius 0 0;
			}

			&:last-child {
				border-radius: 0 0 $border-radius $border-radius;
			}

			&:hover,
			&.active {
				background: $gray-900;
				ul {
					display: block;
					z-index: 1;
				}
			}

			&:after {
				content: "";
				height: 1px;
				background: $gray-600;
				position: absolute;
				left: $btn-padding-x;
				right: $btn-padding-x;
				bottom: 0
			}

			.icon {
				width: 1em;
				display: inline-block;
			}

			ul {
				display: none;
				position: absolute;
				top: 0;
				width: 100%;
				background: $gray-900;

				&[data-direction="left"] {
					left: 96%;
				}

				&[data-direction="right"] {
					right: 96%;
				}

			}

			
		}
	}

}