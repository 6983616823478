
//──── ERPL Social links Vertical ────────────────────────────────────────────────────────

.erpl_social-share-vertical a {
	height: 35px;
	width: 35px;
	border: 1px solid $gray-900;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.erpl_social-share-vertical a:hover {
	background: $primary
}

// black to white icon
.erpl_social-share-vertical a:hover i.erpl_icon-facebook-dark:after {
	background-position: -135px -44px;
    width: 9px;
    height: 18px;
}

.erpl_social-share-vertical a:hover i.erpl_icon-twitter-dark:after {
    background-position: -132px -146px;
    width: 15px;
    height: 13px;
}

.erpl_social-share-vertical a:hover i.erpl_icon-linkedin-dark:after {
	background-position: -132px -105px;
    width: 15px;
    height: 14px;
}

.erpl_social-share-vertical a:hover i.erpl_icon-google-dark:after {
    background-position: -131px -226px;
    width: 17px;
    height: 12px;
}


.erpl_social-share-vertical a:hover i.erpl_icon-whatsapp-dark:after {
	background-position: -23px -586px;
	width: 18px;
	height: 18px;
}
