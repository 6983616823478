
//──── ERPL DROPDOWN ─────────────────────────────────────────────────────────────────────
.erpl_dropdown, .erpl_dropdown-custom {
	position: relative;

	.erpl_dropdown-btn {

		appearance: none;
		border-radius: inherit;
		padding: inherit;
		font: inherit;
		border: inherit;
		text-align: left;
		cursor: pointer;

		&[aria-expanded="true"] {

			z-index: $zindex-dropdown + 1;

			.form-control,
			.input-group-append .erpl_icon {
					border-color: $primary;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					border-bottom: none;
				}

			
		} 

		&:hover:not([aria-expanded="true"]),
		&[disabled] {
			.form-control,
			i.erpl_icon {
				background: $gray-200
			}
		}
	}



	&[data-position="absolute"] {


		.erpl_dropdown-content {
			position: absolute;
			z-index: $zindex-dropdown;
			background: $white;
			left: 0;
			right: 0;

			> .border {
				position: relative;
				top: -1px;
			}
		}
	}

	&[data-content-width="auto"] {
		.erpl_dropdown-content {
			left: auto;
			right: auto;
			min-width: 100%;
		}
	}	
	&[data-max-height="true"],
	&[data-max-height="auto"] {
		.erpl_dropdown-content > * {
			max-height: 250px;
			overflow: auto;
		}
	}

	.erpl_dropdown-content {
		.erpl_dropdown-menu {
			.erpl_dropdown-menu-item {
				&:focus {
					outline: none;
					box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);

				}
			}
		}
	}

}

