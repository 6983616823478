//──── CAPTCHA ───────────────────────────────────────────────────────────────────────────

.erpl_captcha {
    .erpl_captcha-body,
    .erpl_captcha-header,
    .erpl_captcha-footer {
        display: none;
    }
    
    &[data-mode="loading"] {
        .erpl_captcha-body[data-mode="loading"] {
            display: block;
        }
    }

    &[data-mode="visual"] {
        .erpl_captcha-header[data-mode="visual"],
        .erpl_captcha-footer {
            display: block
        }
        .erpl_captcha-body[data-mode="visual"] {
            display: grid;
        }

        .erpl_captcha-footer button[data-mode="visual"]{
            display: none
        }
    }

    &[data-mode="audio"] {
        .erpl_captcha-header[data-mode="audio"],
        .erpl_captcha-footer {
            display: block
        }

        .erpl_captcha-body[data-mode="audio"] {
            display: block;
        }

        .erpl_captcha-footer button[data-mode="audio"]{
            display: none
        }
    }

    .erpl_captcha-body[data-mode="visual"] {
        grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
        grid-auto-flow: row;

        .spinner {
            grid-column: 2;
        }

        .erpl_captcha-item {
            transition: .3s;
            margin: .1rem;
            cursor: pointer;

            &[data-selected=true] {
                margin: .75rem;
                outline: 1px solid $primary;
     
                .erpl_icon {
                    opacity: 1;
                }
            }

            .erpl_icon {
                position: absolute;
                top:-10px;
                left: -10px;
                padding: 3px;
                background: $primary;
                border-radius: 50%;
                opacity: 0;
                transition: .3s;
                box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
                transform: scale(.75)
            }
        }
    }

}