//──── Effet de lightbox ─────────────────────────────────────────────────────────────────
body[data-light-box="true"] {
	overflow: hidden;
}

body[data-light-box="true"]:after {
	background: rgba(0,0,0,0.2);
	content: "";
	left:0;
	right: 0;
	bottom: 0;
	top: 0;
	display: block;
	position: fixed;
	z-index: 9;
}