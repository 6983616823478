//──── ERPL Autocomplete MEPS ────────────────────────────────────────────────────────────────────

.erpl_autocomplete-meps {

    &.ui-menu {
        overflow: auto;
        max-height: 270px;

        .ui-menu-item {
            img {
                height:44px;
                width:35px;
                margin-top:6px;
                margin-bottom:8px;
                margin-left:0px;
                border:0px!important;
            }

            .t-x-block.ui-menu-item:hover {
                border-bottom:1px solid $primary!important;
                border-top:1px solid $primary!important;

            }
        }
    }


}

.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front.erpl_autocomplete-meps .ui-menu-item-wrapper{
    border:none!important;

}




