//──── ERPL TAGS ─────────────────────────────────────────────────────────────────────────
.erpl_tags .btn-default:hover {
	border-color: $primary;
	background: $primary; 
	color: $white
}

.erpl_tags .btn-default i.erpl_icon-tag{
	vertical-align: middle
}

.erpl_tags .btn-default:hover i.erpl_icon-tag:after {
	background-position: -65px -122px;
}
