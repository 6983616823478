
//========================================================================================
/*                                                                                      *
 *                                       Flag icon                                      *
 *                                                                                      */
//========================================================================================

/* FLAGS CLASSES */
[class^="flag-icon"] {
    width: 29px;
    height: 19px;
    text-indent: -10000px;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 1000%;
   	box-shadow: 0 0 0 1px #d1d3d4;
   	background-color: #fff;
   	background-size: cover;
}

[class^="flag-icon"].flag-sm {
    width: 19px;
    height: 12px;
}
[class^="flag-icon"].flag-lg {
    width: 40px;
    height: 26px;
}
[class^="flag-icon"].flag-xl {
    width: 60px;
    height: 40px;
}


.flag-icon-empty {
    background: none;
    width: 10px;
}
[class^="flag-icon-"]:last-of-type::before {
    content: "";
    display: block;
    height: 19px;
    margin-left: 30px;
    position: absolute;
    text-indent: 0 !important;
    width: 50px;
}
.flag-icon-ad {
   background-image: url("#{$assetsPath}/img/flags/png/ad.png");
}
.flag-icon-ae {
    background-image: url("#{$assetsPath}/img/flags/png/ae.png");
}
.flag-icon-af {
	background-image: url("#{$assetsPath}/img/flags/png/af.png");
}
.flag-icon-ag {
    background-image: url("#{$assetsPath}/img/flags/png/ag.png");
}
.flag-icon-al {
	background-image: url("#{$assetsPath}/img/flags/png/al.png");
}
.flag-icon-am {
    background-image: url("#{$assetsPath}/img/flags/png/am.png");
}
.flag-icon-ao {
    background-image: url("#{$assetsPath}/img/flags/png/ao.png");
}
.flag-icon-aq {
    background-image: url("#{$assetsPath}/img/flags/png/aq.png");
}
.flag-icon-ar {
    background-image: url("#{$assetsPath}/img/flags/png/ar.png");
}
.flag-icon-as {
    background-image: url("#{$assetsPath}/img/flags/png/as.png");
}
.flag-icon-at {
    background-image: url("#{$assetsPath}/img/flags/png/at.png");
}
.flag-icon-au {
    background-image: url("#{$assetsPath}/img/flags/png/au.png");
}
.flag-icon-aw {
    background-image: url("#{$assetsPath}/img/flags/png/aw.png");
}
.flag-icon-az {
    background-image: url("#{$assetsPath}/img/flags/png/az.png");
}
.flag-icon-ba {
    background-image: url("#{$assetsPath}/img/flags/png/ba.png");
}
.flag-icon-bb {
    background-image: url("#{$assetsPath}/img/flags/png/bb.png");
}
.flag-icon-bd {
    background-image: url("#{$assetsPath}/img/flags/png/bd.png");
}
.flag-icon-be {
    background-image: url("#{$assetsPath}/img/flags/png/be.png");
}
.flag-icon-bf {
    background-image: url("#{$assetsPath}/img/flags/png/bf.png");
}
.flag-icon-bg {
    background-image: url("#{$assetsPath}/img/flags/png/bg.png");
}
.flag-icon-bh {
    background-image: url("#{$assetsPath}/img/flags/png/bh.png");
}
.flag-icon-bi {
    background-image: url("#{$assetsPath}/img/flags/png/bi.png");
}
.flag-icon-bj {
    background-image: url("#{$assetsPath}/img/flags/png/bj.png");
}
.flag-icon-bn {
    background-image: url("#{$assetsPath}/img/flags/png/bn.png");
}
.flag-icon-bo {
    background-image: url("#{$assetsPath}/img/flags/png/bo.png");
}
.flag-icon-br {
    background-image: url("#{$assetsPath}/img/flags/png/br.png");
}
.flag-icon-bs {
    background-image: url("#{$assetsPath}/img/flags/png/bs.png");
}
.flag-icon-bt {
    background-image: url("#{$assetsPath}/img/flags/png/bt.png");
}
.flag-icon-bw {
    background-image: url("#{$assetsPath}/img/flags/png/bw.png");
}
.flag-icon-by {
    background-image: url("#{$assetsPath}/img/flags/png/by.png");
}
.flag-icon-bz {
    background-image: url("#{$assetsPath}/img/flags/png/bz.png");
}
.flag-icon-ca {
    background-image: url("#{$assetsPath}/img/flags/png/ca.png");
}
.flag-icon-cd {
    background-image: url("#{$assetsPath}/img/flags/png/cd.png");
}
.flag-icon-cf {
    background-image: url("#{$assetsPath}/img/flags/png/cf.png");
}
.flag-icon-cg {
    background-image: url("#{$assetsPath}/img/flags/png/cg.png");
}
.flag-icon-ch {
    background-image: url("#{$assetsPath}/img/flags/png/ch.png");
}
.flag-icon-ci {
    background-image: url("#{$assetsPath}/img/flags/png/ci.png");
}
.flag-icon-ck {
    background-image: url("#{$assetsPath}/img/flags/png/ck.png");
}
.flag-icon-cl {
    background-image: url("#{$assetsPath}/img/flags/png/cl.png");
}
.flag-icon-cm {
    background-image: url("#{$assetsPath}/img/flags/png/cm.png");
}
.flag-icon-cn {
    background-image: url("#{$assetsPath}/img/flags/png/cn.png");
}
.flag-icon-co {
    background-image: url("#{$assetsPath}/img/flags/png/co.png");
}
.flag-icon-cr {
    background-image: url("#{$assetsPath}/img/flags/png/cr.png");
}

.flag-icon-cu {
    background-image: url("#{$assetsPath}/img/flags/png/cu.png");
}
.flag-icon-cv {
    background-image: url("#{$assetsPath}/img/flags/png/cv.png");
}
.flag-icon-cw {
    background-image: url("#{$assetsPath}/img/flags/png/cw.png");
}
.flag-icon-cy {
    background-image: url("#{$assetsPath}/img/flags/png/cy.png");
}
.flag-icon-cz {
    background-image: url("#{$assetsPath}/img/flags/png/cz.png");
}
.flag-icon-de {
    background-image: url("#{$assetsPath}/img/flags/png/de.png");
}
.flag-icon-dj {
    background-image: url("#{$assetsPath}/img/flags/png/dj.png");
}
.flag-icon-dk {
    background-image: url("#{$assetsPath}/img/flags/png/dk.png");
}
.flag-icon-dm {
    background-image: url("#{$assetsPath}/img/flags/png/dm.png");
}
.flag-icon-do {
    background-image: url("#{$assetsPath}/img/flags/png/do.png");
}
.flag-icon-dz {
    background-image: url("#{$assetsPath}/img/flags/png/dz.png");
}
.flag-icon-ec {
    background-image: url("#{$assetsPath}/img/flags/png/ec.png");
}
.flag-icon-ee {
    background-image: url("#{$assetsPath}/img/flags/png/ee.png");
}
.flag-icon-eg {
    background-image: url("#{$assetsPath}/img/flags/png/eg.png");
}
.flag-icon-eh {
    background-image: url("#{$assetsPath}/img/flags/png/eh.png");
}
.flag-icon-er {
    background-image: url("#{$assetsPath}/img/flags/png/er.png");
}
.flag-icon-es {
    background-image: url("#{$assetsPath}/img/flags/png/es.png");
}
.flag-icon-et {
    background-image: url("#{$assetsPath}/img/flags/png/et.png");
}
.flag-icon-eu {
    background-image: url("#{$assetsPath}/img/flags/png/ue.png");
}
.flag-icon-fi {
    background-image: url("#{$assetsPath}/img/flags/png/fi.png");
}
.flag-icon-fj {
    background-image: url("#{$assetsPath}/img/flags/png/fj.png");
}
.flag-icon-fm {
    background-image: url("#{$assetsPath}/img/flags/png/fm.png");
}
.flag-icon-fr {
    background-image: url("#{$assetsPath}/img/flags/png/fr.png");
}
.flag-icon-ga {
    background-image: url("#{$assetsPath}/img/flags/png/ga.png");
}
.flag-icon-gb {
    background-image: url("#{$assetsPath}/img/flags/png/gb.png");
}
.flag-icon-gd {
    background-image: url("#{$assetsPath}/img/flags/png/gd.png");
}
.flag-icon-ge {
    background-image: url("#{$assetsPath}/img/flags/png/ge.png");
}
.flag-icon-gh {
    background-image: url("#{$assetsPath}/img/flags/png/gh.png");
}
.flag-icon-gm {
    background-image: url("#{$assetsPath}/img/flags/png/gm.png");
}
.flag-icon-gn {
    background-image: url("#{$assetsPath}/img/flags/png/gn.png");
}
.flag-icon-gq {
    background-image: url("#{$assetsPath}/img/flags/png/gq.png");
}
.flag-icon-gr {
    background-image: url("#{$assetsPath}/img/flags/png/gr.png");
}
.flag-icon-gt {
    background-image: url("#{$assetsPath}/img/flags/png/gt.png");
}
.flag-icon-gw {
    background-image: url("#{$assetsPath}/img/flags/png/gw.png");
}
.flag-icon-gy {
    background-image: url("#{$assetsPath}/img/flags/png/gy.png");
}
.flag-icon-hn {
    background-image: url("#{$assetsPath}/img/flags/png/hn.png");
}
.flag-icon-hr {
    background-image: url("#{$assetsPath}/img/flags/png/hr.png");
}
.flag-icon-ht {
    background-image: url("#{$assetsPath}/img/flags/png/ht.png");
}
.flag-icon-hu {
    background-image: url("#{$assetsPath}/img/flags/png/hu.png");
}
.flag-icon-id {
    background-image: url("#{$assetsPath}/img/flags/png/id.png");
}
.flag-icon-ie {
    background-image: url("#{$assetsPath}/img/flags/png/ie.png");
}
.flag-icon-il {
    background-image: url("#{$assetsPath}/img/flags/png/il.png");
}
.flag-icon-in {
    background-image: url("#{$assetsPath}/img/flags/png/in.png");
}
.flag-icon-iq {
    background-image: url("#{$assetsPath}/img/flags/png/iq.png");
}
.flag-icon-ir {
    background-image: url("#{$assetsPath}/img/flags/png/ir.png");
}
.flag-icon-is {
    background-image: url("#{$assetsPath}/img/flags/png/is.png");
}
.flag-icon-it {
    background-image: url("#{$assetsPath}/img/flags/png/it.png");
}
.flag-icon-jm {
    background-image: url("#{$assetsPath}/img/flags/png/jm.png");
}
.flag-icon-jo {
    background-image: url("#{$assetsPath}/img/flags/png/jo.png");
}
.flag-icon-jp {
    background-image: url("#{$assetsPath}/img/flags/png/jp.png");
}
.flag-icon-ke {
    background-image: url("#{$assetsPath}/img/flags/png/ke.png");
}
.flag-icon-kg {
    background-image: url("#{$assetsPath}/img/flags/png/kg.png");
}
.flag-icon-kh {
    background-image: url("#{$assetsPath}/img/flags/png/kh.png");
}
.flag-icon-ki {
    background-image: url("#{$assetsPath}/img/flags/png/ki.png");
}
.flag-icon-km {
    background-image: url("#{$assetsPath}/img/flags/png/km.png");
}
.flag-icon-kn {
    background-image: url("#{$assetsPath}/img/flags/png/kn.png");
}
.flag-icon-kp {
    background-image: url("#{$assetsPath}/img/flags/png/kp.png");
}
.flag-icon-kr {
    background-image: url("#{$assetsPath}/img/flags/png/kr.png");
}
.flag-icon-kw {
    background-image: url("#{$assetsPath}/img/flags/png/kw.png");
}
.flag-icon-kz {
    background-image: url("#{$assetsPath}/img/flags/png/kz.png");
}
.flag-icon-la {
    background-image: url("#{$assetsPath}/img/flags/png/la.png");
}
.flag-icon-lb {
    background-image: url("#{$assetsPath}/img/flags/png/lb.png");
}
.flag-icon-lc {
    background-image: url("#{$assetsPath}/img/flags/png/lc.png");
}
.flag-icon-li {
    background-image: url("#{$assetsPath}/img/flags/png/li.png");
}
.flag-icon-lk {
    background-image: url("#{$assetsPath}/img/flags/png/lk.png");
}
.flag-icon-lr {
    background-image: url("#{$assetsPath}/img/flags/png/lr.png");
}
.flag-icon-ls {
    background-image: url("#{$assetsPath}/img/flags/png/ls.png");
}
.flag-icon-lt {
    background-image: url("#{$assetsPath}/img/flags/png/lt.png");
}
.flag-icon-lu {
    background-image: url("#{$assetsPath}/img/flags/png/lu.png");
}
.flag-icon-lv {
    background-image: url("#{$assetsPath}/img/flags/png/lv.png");
}
.flag-icon-ly {
    background-image: url("#{$assetsPath}/img/flags/png/ly.png");
}
.flag-icon-ma {
    background-image: url("#{$assetsPath}/img/flags/png/ma.png");
}
.flag-icon-mc {
    background-image: url("#{$assetsPath}/img/flags/png/mc.png");
}
.flag-icon-md {
    background-image: url("#{$assetsPath}/img/flags/png/md.png");
}
.flag-icon-me {
    background-image: url("#{$assetsPath}/img/flags/png/me.png");
}
.flag-icon-mf {
    background-image: url("#{$assetsPath}/img/flags/png/fr.png");
}
.flag-icon-mg {
    background-image: url("#{$assetsPath}/img/flags/png/mg.png");
}
.flag-icon-mh {
    background-image: url("#{$assetsPath}/img/flags/png/mh.png");
}
.flag-icon-mk {
    background-image: url("#{$assetsPath}/img/flags/png/mk.png");
}
.flag-icon-ml {
	background-image: url("#{$assetsPath}/img/flags/png/ml.png");
}
.flag-icon-mm {
	background-image: url("#{$assetsPath}/img/flags/png/mm.png");
}
.flag-icon-mn {
	background-image: url("#{$assetsPath}/img/flags/png/mn.png");
}
.flag-icon-mr {
	background-image: url("#{$assetsPath}/img/flags/png/mr.png");
}
.flag-icon-mt {
	background-image: url("#{$assetsPath}/img/flags/png/mt.png");
}
.flag-icon-mu {
	background-image: url("#{$assetsPath}/img/flags/png/mu.png");
}
.flag-icon-mv {
	background-image: url("#{$assetsPath}/img/flags/png/mv.png");
}
.flag-icon-mw {
	background-image: url("#{$assetsPath}/img/flags/png/mw.png");
}
.flag-icon-mx {
	background-image: url("#{$assetsPath}/img/flags/png/mx.png");
}
.flag-icon-my {
	background-image: url("#{$assetsPath}/img/flags/png/my.png");
}
.flag-icon-mz {
	background-image: url("#{$assetsPath}/img/flags/png/mz.png");
}
.flag-icon-na {
	background-image: url("#{$assetsPath}/img/flags/png/na.png");
}
.flag-icon-ne {
	background-image: url("#{$assetsPath}/img/flags/png/ne.png");
}
.flag-icon-ng {
	background-image: url("#{$assetsPath}/img/flags/png/ng.png");
}
.flag-icon-ni {
	background-image: url("#{$assetsPath}/img/flags/png/ni.png");
}
.flag-icon-nl {
	background-image: url("#{$assetsPath}/img/flags/png/nl.png");
}
.flag-icon-no {
	background-image: url("#{$assetsPath}/img/flags/png/no.png");
}
.flag-icon-np {
	background-image: url("#{$assetsPath}/img/flags/png/np.png");
}
.flag-icon-nr {
	background-image: url("#{$assetsPath}/img/flags/png/nr.png");
}
.flag-icon-nu {
	background-image: url("#{$assetsPath}/img/flags/png/nu.png");
}
.flag-icon-nz {
	background-image: url("#{$assetsPath}/img/flags/png/nz.png");
}
.flag-icon-om {
	background-image: url("#{$assetsPath}/img/flags/png/om.png");
}
.flag-icon-pa {
	background-image: url("#{$assetsPath}/img/flags/png/pa.png");
}
.flag-icon-pe {
	background-image: url("#{$assetsPath}/img/flags/png/pe.png");
}
.flag-icon-pg {
	background-image: url("#{$assetsPath}/img/flags/png/pg.png");
}
.flag-icon-ph {
	background-image: url("#{$assetsPath}/img/flags/png/ph.png");
}
.flag-icon-pk {
	background-image: url("#{$assetsPath}/img/flags/png/pk.png");
}
.flag-icon-pl {
	background-image: url("#{$assetsPath}/img/flags/png/pl.png");
}
.flag-icon-ps {
	background-image: url("#{$assetsPath}/img/flags/png/ps.png");
}
.flag-icon-pt {
	background-image: url("#{$assetsPath}/img/flags/png/pt.png");
}
.flag-icon-pw {
	background-image: url("#{$assetsPath}/img/flags/png/pw.png");
}
.flag-icon-py {
	background-image: url("#{$assetsPath}/img/flags/png/py.png");
}
.flag-icon-qa {
	background-image: url("#{$assetsPath}/img/flags/png/qa.png");
}
.flag-icon-ro {
	background-image: url("#{$assetsPath}/img/flags/png/ro.png");
}
.flag-icon-rs {
	background-image: url("#{$assetsPath}/img/flags/png/rs.png");
}
.flag-icon-ru {
	background-image: url("#{$assetsPath}/img/flags/png/ru.png");
}
.flag-icon-rw {
	background-image: url("#{$assetsPath}/img/flags/png/rw.png");
}
.flag-icon-sa {
	background-image: url("#{$assetsPath}/img/flags/png/sa.png");
}
.flag-icon-sb {
	background-image: url("#{$assetsPath}/img/flags/png/sb.png");
}
.flag-icon-sc {
	background-image: url("#{$assetsPath}/img/flags/png/sc.png");
}
.flag-icon-sd {
	background-image: url("#{$assetsPath}/img/flags/png/sd.png");
}
.flag-icon-se {
	background-image: url("#{$assetsPath}/img/flags/png/se.png");
}
.flag-icon-sg {
	background-image: url("#{$assetsPath}/img/flags/png/sg.png");
}
.flag-icon-si {
	background-image: url("#{$assetsPath}/img/flags/png/si.png");
}
.flag-icon-sk {
	background-image: url("#{$assetsPath}/img/flags/png/sk.png");
}
.flag-icon-sl {
	background-image: url("#{$assetsPath}/img/flags/png/sl.png");
}
.flag-icon-sm {
	background-image: url("#{$assetsPath}/img/flags/png/sm.png");
}
.flag-icon-sn {
	background-image: url("#{$assetsPath}/img/flags/png/sn.png");
}
.flag-icon-so {
	background-image: url("#{$assetsPath}/img/flags/png/so.png");
}
.flag-icon-sr {
	background-image: url("#{$assetsPath}/img/flags/png/sr.png");
}
.flag-icon-st {
	background-image: url("#{$assetsPath}/img/flags/png/st.png");
}
.flag-icon-sv {
	background-image: url("#{$assetsPath}/img/flags/png/sv.png");
}
.flag-icon-sx {
	background-image: url("#{$assetsPath}/img/flags/png/sx.png");
}
.flag-icon-sy {
	background-image: url("#{$assetsPath}/img/flags/png/sy.png");
}
.flag-icon-sz {
	background-image: url("#{$assetsPath}/img/flags/png/sz.png");
}
.flag-icon-td {
	background-image: url("#{$assetsPath}/img/flags/png/td.png");
}
.flag-icon-tg {
	background-image: url("#{$assetsPath}/img/flags/png/tg.png");
}
.flag-icon-th {
	background-image: url("#{$assetsPath}/img/flags/png/th.png");
}
.flag-icon-tj {
	background-image: url("#{$assetsPath}/img/flags/png/tj.png");
}
.flag-icon-tl {
	background-image: url("#{$assetsPath}/img/flags/png/tl.png");
}
.flag-icon-tm {
	background-image: url("#{$assetsPath}/img/flags/png/tm.png");
}
.flag-icon-tn {
	background-image: url("#{$assetsPath}/img/flags/png/tn.png");
}
.flag-icon-to {
	background-image: url("#{$assetsPath}/img/flags/png/to.png");
}
.flag-icon-tr {
	background-image: url("#{$assetsPath}/img/flags/png/tr.png");
}
.flag-icon-tt {
	background-image: url("#{$assetsPath}/img/flags/png/tt.png");
}
.flag-icon-tv {
	background-image: url("#{$assetsPath}/img/flags/png/tv.png");
}
.flag-icon-tz {
	background-image: url("#{$assetsPath}/img/flags/png/tz.png");
}
.flag-icon-ua {
	background-image: url("#{$assetsPath}/img/flags/png/ua.png");
}
.flag-icon-ue {
	background-image: url("#{$assetsPath}/img/flags/png/ue.png");
}
.flag-icon-ug {
	background-image: url("#{$assetsPath}/img/flags/png/ug.png");
}
.flag-icon-uk {
	background-image: url("#{$assetsPath}/img/flags/png/uk.png");
}
.flag-icon-us {
	background-image: url("#{$assetsPath}/img/flags/png/us.png");
}
.flag-icon-uy {
	background-image: url("#{$assetsPath}/img/flags/png/uy.png");
}
.flag-icon-uz {
	background-image: url("#{$assetsPath}/img/flags/png/uz.png");
}
.flag-icon-va {
	background-image: url("#{$assetsPath}/img/flags/png/va.png");
}
.flag-icon-vc {
	background-image: url("#{$assetsPath}/img/flags/png/vc.png");
}
.flag-icon-ve {
	background-image: url("#{$assetsPath}/img/flags/png/ve.png");
}
.flag-icon-vn {
	background-image: url("#{$assetsPath}/img/flags/png/vn.png");
}
.flag-icon-vu {
	background-image: url("#{$assetsPath}/img/flags/png/vu.png");
}
.flag-icon-ws {
	background-image: url("#{$assetsPath}/img/flags/png/ws.png");
}
.flag-icon-xk {
	background-image: url("#{$assetsPath}/img/flags/png/xk.png");
}
.flag-icon-ye {
	background-image: url("#{$assetsPath}/img/flags/png/ye.png");
}
.flag-icon-za {
	background-image: url("#{$assetsPath}/img/flags/png/za.png");
}
.flag-icon-zm {
	background-image: url("#{$assetsPath}/img/flags/png/zm.png");
}
.flag-icon-zw {
	background-image: url("#{$assetsPath}/img/flags/png/zw.png");
}
.flag-icon-separator {
    background: none;
    border-left: 1px solid black;
    margin-left: 11px;
    margin-right: 12px;
    width: 1px;
    box-shadow: 0 0 0 0;
}