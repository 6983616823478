//──── ERPL Full width section ───────────────────────────────────────────────────────────
.erpl_full-width-section {
    background-color: #eff0f0;
}

.erpl_full-width-section .erpl_full-width-section-heading  {
	background: $gray-900;
	color: $white;
	display: inline-block;
	padding: .625rem;
}

.erpl_full-width-section .erpl_full-width-section-footer {
	position: relative;
}

.erpl_full-width-section .erpl_full-width-section-footer .btn-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

}


.erpl_full-width-section .erpl_full-width-section-footer .btn {
	padding: 14px 30px 13px
}