.erpl_toast-container {
    position: fixed;

    background: transparent;
    z-index: 1000;
    transition: .5s;
    display: flex;
    flex-direction: column;
    align-content: center;
    // align-items: flex-end;

    &[data-position="top right"] {
        right: 0;
        top: 0;
    }

    &[data-position="top left"] {
        left: 0;
        top: 0;
    }

    &[data-position="bottom left"] {
        bottom: 0;
        left: 0;
    }

    &[data-position="bottom right"] {
        bottom: 0;
        right: 0;
    }

	.erpl_toast {
        transition: .5s;
        animation-duration: .5s;
        position: relative;
        cursor: pointer;
        &:hover {
            filter: contrast(1.2)
        }

        .erpl_toast-progressbar {
            height: 3px;
            animation-name: animProgressbar;
            background: #000;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: .35;
        }

        &.toastOut {
            
            overflow: hidden;
            height: 0;
            padding: 0;
            margin: 0;
            border: none;
        }
    }

    @keyframes animToast {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }

    }

    @keyframes animProgressbar {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }

}