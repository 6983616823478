
//──── ERPL title ────────────────────────────────────────────────────────────────────────
.erpl_title-h1, h1 {
	font-size: 1.5625rem
}


.erpl_title-h2, h2 {
	font-size: 1.25rem;
	line-height: 1.2em;
}

.erpl_title-h3, h3 {
	font-size: 1.25rem;
	line-height: 1.2em;
}

.erpl_title-h4, h4 {
	font-size: 1rem;
	font-weight: 700;	
}

.erpl_title-h5, h5 {
	font-size: .875rem;
	font-weight: 700;	
}

.erpl_title-h6, h6 {
	font-size: .75rem;
	font-weight: 700;	
}

.erpl_title-h1, h1,
.erpl_title-h2, h2,
.erpl_title-h3, h3 {
	font-family: $font-family-serif;
}

.erpl_title-h4, h4,
.erpl_title-h5, h5,
.erpl_title-h6, h6 {
	font-family: $font-family-sans-serif
}
@media (min-width: map-get($map: $grid-breakpoints, $key: sm)){
	.erpl_title-h1, h1 {
		font-size: 1.875rem
	}

	.erpl_title-h2, h2 {
		font-size: 1.25rem
	}

	.erpl_title-h3, h3 {
		font-size: 1.25rem
	}

	.erpl_title-h4, h4 {
		font-size: 1rem;
		font-weight: 700;	
	}
}
@media (min-width: map-get($map: $grid-breakpoints, $key: xl)){
	.erpl_title-h1, h1 {
		font-size: 2.1875rem
	}

	.erpl_title-h2, h2 {
		font-size: 1.5625rem
	}

	.erpl_title-h3, h3 {
		font-size: 1.25rem
	}

	.erpl_title-h4, h4 {
		font-size: 1rem;
		font-weight: 700;	
	}
}
