@mixin MenuIsMobileOrSticky {
	

	.erpl_header-menu-top {
		display: flex;
		.erpl_header-menu-top-controls {
			display: flex;
			justify-content: flex-end;
			.btn-dark-primary {
				display: inline-flex;
				align-items: center;

				&:not(:last-child) {
					margin-right: .5rem;
				}
				&:focus{
					outline: 3px dotted $gray-500;
					outline-offset: -3px;
					box-shadow: none;
				}
			}
			.erpl_header-menu-top-controls-toggle-menu {
				text-transform: uppercase;

				&:hover {
					.erpl_header-menu-icon {
						background: $primary;
						&:after, &:before {
							background: $primary;
							transition: transform .5s;

						}
					}
				}
				.erpl_header-menu-icon {
					height: 2px;
					background: $white;
					width: 15px;
					display: inline-block;
					position: relative;
					&:after, &:before {
						content: "";
						width: 15px;
						height: 2px;
						position: absolute;
						background: $white;
						left: 0;
					}
					&:before {
						top: -5px

					}

					&:after {
						bottom: -5px;
					}
				}
			}
		}

		.erpl_header-menu-top-title {
			span {
				display: block;
			}
		}
	}
		
			
	
	.erpl_header-menu {
		position: relative;

		// SEARCH
		> .erpl_header-search {
			
			display: none;
			top: 0px;
			position: fixed;
			left: 0;
			right: 0;
			z-index: $zindex-fixed;
			background: $gray-200;
		
		} 

		// MENU
		> ul.erpl_header-menu-list {
			position: absolute; 
			flex-direction: column;
			background: $white;
			z-index: 1;
			box-shadow: 0 4px 5px rgba(0, 0, 0, .2);
			right: 1px;
			min-width: 280px;
			height: 100vh;
			display: none;

			> li.erpl_header-menu-item {
				&:not(:last-child) {
					border-bottom: 1px solid $border-color;
				}
				> .erpl_header-menu-item-title {
					color: inherit;
					&[tabindex] {
						cursor: pointer;
					}
					&[data-selected=true]{
						color: $primary;
						&:after {
							background: $primary;
							height: 100%;
							position: absolute;
							bottom: 0;
							left: 0;
							width: 4px;
						}
					}

					span {
						justify-content: space-between;

						.erpl_icon {
							border: 1px solid;
							border-radius: 50%;
							width: 19px;
							height: 19px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}

				> ul {
					position: initial;
				}

				
			}
			
		}
	}

}

header.erpl_header {
	background: #fafafa;
	position: relative;

	.sr-only.sr-only-focusable {
		&:focus {
			position: absolute;
			z-index: 1;
		}
	}

	.erpl_header-top {

		*:focus {
			background: $gray-201;
			outline: 3px dotted $gray-500;
			outline-offset: -3px;
			box-shadow: none;
		}

		.erpl_header-other-websites {
			font-size: .8125rem;
			> ul {
				margin: 0;
				max-width: 100%;
				li {
					a {
						height: 40px;
					}
				}
			}
		}
		.erpl_dropdown {
			z-index: 99999;
			display: inline-block;
			max-width: 100%;
			.erpl_dropdown-btn {
				background: none;
				height: 40px;
	
				&:hover {
					.value {
						color: $primary
					}
				}
	
	
				.value {
					background: none;
					border: none;
					padding-right: 0;
					color: $gray-700;
					font-size: .8125rem;
				}
	
				&.input-group {
					align-items: center;
					& >.input-group-append > .erpl_icon {
						background: none;
						border: none
					}
				}
	
				&:not(.collapsed), &:hover {
					background: $gray-200;
	
					&.input-group {
						& >.input-group-append > .erpl_icon:after {
							background-position: -175px -20px;
	
						}
					}
				}
	
				&:not(.collapsed) {
	
					&.input-group {
						& >.input-group-append > .erpl_icon:after {
							transform: scaleY(-1);
	
						}
					}
				}
			}
	
			> div {
	
				.erpl_dropdown-content {
					background: $gray-200;
					transition: height .05s ease;
		
					ul {
						overflow-y: auto;
						overflow-x: hidden;
						max-height: calc(50vh - 40px);
						margin: 0;
						li {
		
							> * {
								padding: .625rem .75rem;
								display: block;
								white-space: nowrap;
								position: relative;
	
								&:after {
									display: block;
									height: 1px;
									background: $border-color;
									content: "";
									left: .75rem;
									right: .75rem;
									position: absolute;
									bottom: 0
								}
	
								&:focus {
									background: $gray-201;
									outline: 3px dotted $gray-500;
									outline-offset: -3px;
									box-shadow: none;
								}
							}
	
							&[data-selected="true"] {
								background: $white;
	
								> * {
									border-bottom: none;
									&:before {
										display: block;
										height: 1px;
										background: $primary;
										content: "";
										left: .75rem;
										right: .75rem;
										position: absolute;
										top: 0
									}
	
									&:after {
										background: $primary;
									}
	
									
								}
							}
						}
					}
				}
			}
	
			
		}
		.es_select {
			display: inline-block;
			&.active {
				.es_select-header {
					background-color: $gray-200;
	
				}
			}
	
			.es_select-header {
				border-color: transparent;
				height: 40px;
				background-color: transparent;
				font-size: .8125rem;

				.erpl_icon {
					margin-left: .75rem;
				}
	
				&:hover {
					background-color: $gray-200;
					color: $primary;
				}
	
				&:focus {
					border-color: $primary;
				}
			}
	
			.es_select-body {
				z-index: 1000;
				background: $gray-200;
	
				ul.es_select-options {
					border: none;
					li.es_select-options-item {
						padding: .5rem .75rem;
						&[data-selected="true"] {
							background: $white;
							&:after,
							+ li:after {
								background: $primary;
							}
						}
		
						&:after {
							display: block;
							content: "";
							position: absolute;
							left: .75rem;
							right: .75rem;
							top: 0;
							background: $border-color;
							height: 1px;
						}
						span {
							display: block;
							transition: all .3s ease-in-out;
							&::after {
								content: none;
							}
						}
		
						&:focus, :hover {
							span {
								transform: translateX(5px)
							}
						}
					}
				}
			}
	
	
		}

	}

	.erpl_header-middle {
		.erpl_header-website-title {
			padding-left: 110px;
    		position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 6px;
				width: 1px;
				height: calc(100% - 10px);
				border-right: 1px solid #ccc;
			}

			.erpl_header-website-title-main {
				font-family: Georgia,Garamond,serif;
				line-height: normal;
				font-size: 1.875rem;
				margin-left: 1rem;
			}

			.erpl_header-website-title-sub {
				line-height: normal;
				margin-left: 1rem;
				a:after {
					content: "";
					background: url('#{$assetsPath}/img/ep-logo.svg') no-repeat 0 0;
					width: 82px;
    				height: 45px;
					position: absolute;
					left: 7px;
					bottom: 5px;
					padding-right: 116px;
					margin-right: 20px;
					align-self: flex-end;
					transition: all .2s ease-in-out;

				}
			}

		}

		.erpl_header-search-container {
			.erpl_header-search {
				button {
					&[disabled] {
						background: $gray-300;
						border-color: $gray-300;
						opacity: 1;

						// Utiliser l'icône search light
						.erpl_icon {
							&:after {
								background-position: -146px -1px;
							}
						}
					}
				}
			}
		}
	}

	.erpl_header-bottom {

		.erpl_header-menu-container {
			background-color: $primary;
			background: linear-gradient(180deg,#3d7ac1 0,#345e9c);
			box-shadow: 0 4px 5px rgba(0, 0, 0, .2);
			position: relative;
			z-index: 10;
			display: flex;
			align-items: center;
			height: 40px;

			&.erpl_header-menu-container-small:not(.is-sticky) {
				height: 20px;
			}

			&.is-sticky {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				z-index: $zindex-sticky;

				// Affichage mini logo en position sticky
				.erpl_header-menu .erpl_header-menu-top .erpl_header-menu-top-logo {
					display: inline-block;
				}
			}
			
			.erpl_header-menu-top {
				display: none;
				height: 40px;
			}


			&.is-mobile.search-open, &.is-sticky.search-open {
				.erpl_header-menu {
					.erpl_header-menu-top {

						.erpl_header-menu-top-controls {
							.erpl_header-menu-top-controls-toggle-search {

								background: $gray-200;
								color: $primary;
								position: relative;
								border-bottom-left-radius: 0;
								border-bottom-right-radius: 0;

								&:after {
									content: "";
									height: 10px;
									background: $gray-200;
									position: absolute;
									bottom: -10px;
									left: 0;
									width: 100%;
								}
								
								.erpl_icon {
									height: 16px;
									width: 16px;
									position: relative;

									&:after, &:before {
										content: "";
										background: $primary;
										width: 15px;
										height: 2px;
										position: absolute;
										left: 0;
										top: 10px;
									}
									&:after {
										transform: rotate(-45deg);
									}
									&:before {
										transform: rotate(45deg);
									}
								}
							
								


							
							}
						}
					}

					> .erpl_header-search {
						
						display: block;


						
						width: 100%;
						input {
							text-align: right;
							background: transparent;
							border: none;
							font-size: 1.2em;
							padding: 1.5rem;

							&:focus {
								outline: 3px dotted $gray-500;
								outline-offset: -3px;
								box-shadow: none;
							}
						}

						button.btn {
							background: $gray-200;
							border: none;
							&:focus {
								outline: 3px dotted $gray-500;
								outline-offset: -3px;
								box-shadow: none;
							}
							.erpl_header-search-icon {
								border-right: 2px solid $input-placeholder-color;
								height: 12px;
								width: 12px;
								transform: rotate(45deg);
								border-top: 2px solid $input-placeholder-color;
							}

						}
					
					
					
					}
				}
			}

			&.is-mobile.menu-open, &.is-sticky.menu-open {

				
				.erpl_header-menu {
					z-index: 2;

					.erpl_header-menu-top {
						.erpl_header-menu-top-controls {
							.erpl_header-menu-top-controls-toggle-menu {
								background: $white;
								color: $primary;
								position: relative;
								border-bottom-left-radius: 0;
								border-bottom-right-radius: 0;

								&:after {
									content: "";
									height: 10px;
									background: $white;
									position: absolute;
									bottom: -10px;
									left: 0;
									width: 100%;
								}

								.erpl_header-menu-icon {
									height: 0;

									&:after, &:before {
										top: 0;
										background: $primary;
									}
									&:after {
										transform: rotate(-45deg);
									}
									&:before {
										transform: rotate(45deg);
									}
								}
							}

							
						}
					}
					> ul.erpl_header-menu-list {
						display: block;

						> li.erpl_header-menu-item {
							&.erpl_header-menu-item-organ {
								width: auto;
								padding: inherit;
								.es_select {
									margin-right: inherit;
									.es_select-header {
										border: none;
										height: inherit;

									}
								}
							}

							.erpl_header-menu-item-title {
								.erpl_header-menu-item-current-nav {
									&:after {
										content: none;
									}
								}
							}

							
						}
							
					}
				}
			}

			.erpl_header-menu {
				.erpl_header-menu-top {
	
					.erpl_header-menu-top-logo {
						width: 46px;
						height: 25px;
						position: relative;
						display: none;
						
						&:after {
							content: "";
							background: url('#{$assetsPath}/img/icons.svg');
							background-position: left -46px;
							height: 46px;
							width: 82px;
							transform: scale(.55) translate(-32px, -20px);
							display: block;
							position: absolute;
							top: 0;
							left: 0;
						
						}
					
					}

					.erpl_header-menu-top-current-nav {
						background: $white;
						color: $red;
						padding-left: 1rem;
						padding-right: 1rem;
						font-size: $small-font-size;
						font-weight: bold;
						height: 40px;
						display: inline-flex;
						align-items: center;
					}
	
					.erpl_header-menu-top-title {
						span {
							font-family: $font-family-serif;
							color: $white;
							font-size: 1.25rem;
							display: none;
						}
					}
	

	
				}
				
				// Mode tab
				&[data-mode="tab"] {

					> ul.erpl_header-menu-list {
						.erpl_header-menu-item {
							.erpl_header-menu-item-title[data-selected="true"] {
								color: $primary;

								&:after {
									height: 98%;
									z-index: -1;
									left: 0;
									right: 0;
								}
							}

							&[data-has-submenu="true"] {
								.erpl_header-menu-item-title[data-selected="true"] {
									&:after {
										background: $gray-100;
									}
								}
								
							}
						}
					}
				}

				> ul.erpl_header-menu-list {
					display: flex;
					margin: 0;
					font-weight: 100;
					> li.erpl_header-menu-item {
						&.erpl_header-menu-item-organ {
							width: 205px;
							padding-right: 5px;
							padding-top: 5px;
							.form-control {
								height: 30px;
								border: none;
								border-radius: 0;
								font-size: .8125rem;
								&:focus {
									outline: 3px dotted $gray-500;
									outline-offset: -3px;
								}
							}
						}
						> .erpl_header-menu-item-title {
							color: $white;
							position: relative;
							display: block;
	
	
							&[data-selected="true"] {
								&:after {
									content: "";
									background: $white;
									height: 4px;
									position: absolute;
									bottom: 0;
									left: 1rem;
									right: 1rem;
	
									
								}

								&[aria-haspopup="true"] {
									&:after {
										right: 2rem;
									}
								}
							}
	
							&:focus {
								outline: 3px dotted $gray-500;
								outline-offset: -3px;
							}
							> span {
								padding: 0 1rem;
								height: 40px;
								display: flex;
								align-items: center;

								&.erpl_header-menu-item-current-nav {
									background: $white;
									color: $red;
									padding-left: 1rem;
									padding-right: 1rem;
									font-size: $small-font-size;
									font-weight: bold;

									&:after {
										display: block;
										height: 8px;
										width: 8px;
										content: "";
										background: #fff;
										position: absolute;
										right: -4px;
										top: calc(50% - 4px);
										transform: rotate(45deg);
									}
								}
	
								.erpl_icon {
									margin-left: .5rem;
								}
							}
	
						}
						> ul {
							display: none;
							background-color: $white;
							position: absolute;
							box-shadow: 1px 2px 3px rgba(0, 0, 0, .2);
							max-height: 480px;
							overflow-y: auto;
							overflow-x: hidden;
							z-index: 1;
	
							> li {
								> a, > div {
									padding: 0 1rem;
									height: 40px;
									display: flex;
									align-items: center;
									position: relative;
	
									&:focus {
										outline: 3px dotted $gray-500;
										outline-offset: -3px;
									}
	
									&[data-selected="true"]:after {
										content: "";
										height: 100%;
										width: 4px;
										background: $primary;
										left: 0;
										position: absolute;
									}
								}
							}
						}
	
						&.open {
							> ul {
								display: block;
							}
						}
					}
				}
			}

			// En position sticky
			&.is-sticky {
				@include MenuIsMobileOrSticky()
			}
			// En mode mobile
			@include media-breakpoint-down(xl) {@include MenuIsMobileOrSticky()}

		}
	}
}