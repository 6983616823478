//──── WAI SHORTCUT ──────────────────────────────────────────────────────────────────────
.erpl_wai-access {
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		a {
			width: 0;
			height: 0;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 9;
			overflow: hidden;

			&:focus {
				width: auto;
				min-width: 250px;
				height: auto;
				opacity: 1;
				z-index: 100000;

			}
		}
	}
}