
//──── ERPL SWITCH ───────────────────────────────────────────────────────────────────────

.erpl_switch input + .erpl_switch-btn{
	border-radius: 100px;
	height: 16px;
	width: 32px;
	background: $gray-500;
	position: relative;
	display: inline-block; 
	vertical-align: middle

}

.erpl_switch label {
	cursor: pointer;
}

.erpl_switch input:checked + .erpl_switch-btn{
	background: $gray-900;
}

.erpl_switch.erpl_switch-primary input:checked + .erpl_switch-btn{
	background: $primary;
}

.erpl_switch.erpl_switch-danger input:checked + .erpl_switch-btn{
	background: $danger;
}

.erpl_switch.erpl_switch-success input:checked + .erpl_switch-btn{
	background: $success;
}

.erpl_switch.erpl_switch-warning input:checked + .erpl_switch-btn{
	background: $warning;
}

.erpl_switch.erpl_switch-info input:checked + .erpl_switch-btn{
	background: $info;
}


.erpl_switch input:focus + .erpl_switch-btn{
	outline-color: $gray-900;
	outline-width: 1px;
	outline-style: solid;
}

.erpl_switch.erpl_switch-primary input:focus + .erpl_switch-btn{
	outline-color: $primary;
}

.erpl_switch.erpl_switch-danger input:focus + .erpl_switch-btn{
	outline-color: $danger;
}

.erpl_switch.erpl_switch-success input:focus + .erpl_switch-btn{
	outline-color: $success;
}

.erpl_switch.erpl_switch-warning input:focus + .erpl_switch-btn{
	outline-color: $warning;
}

.erpl_switch.erpl_switch-info input:focus + .erpl_switch-btn{
	outline-color: $info;
}



.erpl_switch input + .erpl_switch-btn:after {
	border-radius: 50%;
	height: 14px;
	width: 14px;
	background: $white;
	display: block;
	content: ""; 
	position: absolute;
	left: 1px;
	top: 1px; 
	transition: .25s
}

.erpl_switch input:checked + .erpl_switch-btn:after {
	left: 17px
}


// Switch LG

.erpl_switch.erpl_switch-lg input + .erpl_switch-btn{
	height: 32px;
	width: 64px;
}
.erpl_switch.erpl_switch-lg input:checked + .erpl_switch-btn:after {
	left: 33px
}


.erpl_switch.erpl_switch-lg input + .erpl_switch-btn:after {
	height: 30px;
	width: 30px;
}


.erpl_switch input:disabled +.erpl_switch-btn {
	opacity: .7;
}