//========================================================================================
/*                                                                                      *
 *                                  ERPL COOKIE POLICY                                  *
 *                                                                                      */
//========================================================================================
.erpl_policy-cookie {
	position: fixed;
	left: 0;
	right: 0 ; 
	bottom: 0;
	z-index: 99999;
	background: rgba(51,51,51,.95)
}

// NEW COOKIE POLICY OPTIN/OUT WIDGET
#ep-opt-js {
	.ep_opt-form {
		margin-top: 0 !important;

		input {
			margin-right: $spacer * .25;
		}
		p {
			font-weight: bold;
		}
	}
}