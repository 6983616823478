//──── ERPL_links-list ───────────────────────────────────────────────────────────────────

.erpl_links-list {
    ul {
        padding: 0;
        list-style-type: none;
        margin: 0;

        li {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px dotted $border-color;

            &:first-child {
                padding-top: 0
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0
            }

            &[data-selected="true"] a {
                color: inherit;

                &:hover {
                    color: #3c77bd
                }
            }
        }
    }

    a {
        display: inline-flex;
        align-items: center;

        i[class*="erpl_icon-arrow-bottom"]:after {
            transform: rotate(270deg)
        }

        .erpl_icon {
            min-width: 20px;
            display: flex;
            justify-content: center;
         }
    }


    // Imbriqué
    .erpl_links-list {
        margin-left: 1.875rem;
        margin-top: 1.25rem;
    }
}







//──── DOWNLOAD A LIST OF DOC ────────────────────────────────────────────────────────────────
#download-list .erpl_links-list li {
    border: 0px!important;
}

@media (min-width: 1020px) {
    #download-list .erpl_links-list li a {
        float: right!important;
    }
}

@media (min-width: 0px) and (max-width: 1019px) {
    #download-list .erpl_links-list li {
        padding: 2px!important;
    }
}

.erpl_links-list.erpl_links-list-nav {
    
    ul li {
        &:first-child {
            padding-top: 1rem;
        }

        &:last-child {
            padding-bottom: 1rem;
        }
    }

    [data-selected="true"] {
        position: relative;
        &:after {
            background: $primary;
            content: "";
            display: block ;
            width: 4px;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: - map-get($spacers, 2);
        }
    }

}