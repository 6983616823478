//──── Breadcrumb ────────────────────────────────────────────────────────────────────────

@media (max-width: map-get($map: $grid-breakpoints, $key: lg) - 1) {

	nav[aria-label="breadcrumb"] ol.breadcrumb li.breadcrumb-item-back::before {
		background: url('#{$assetsPath}/img/icons.svg');
		content: "";
		background-position: -175px -20px;
		width: 11px;
		height: 8px;
		transform: rotate(90deg);
		display: inline-block;
	}	

}