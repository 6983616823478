//──── MAP WORLD ──────────────────────────────────────────────────────────────────
@media (max-width: map-get($map: $grid-breakpoints, $key: sm)-1) {
    .erpl_map {
        background-color: transparent;
        margin-top: -78px;
    }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: sm)) {
    .erpl_map {
        background: -webkit-gradient(linear, left top, left bottom, from(#355d8f), to(#203b5f));
        background: linear-gradient(to bottom, #355d8f 0%, #203b5f 100%);
    }
}

.erpl_map .erpl_zone:hover {
    transform: scale(1)!important;
    transition: -webkit-transform 0.2s ease-out 0s;
    display: block;
    content: " ";
}

.erpl_map .erpl_title-h3 {
    font-size: 1.1rem;
}

@media (max-width: map-get($map: $grid-breakpoints, $key: sm)-1) {
    .erpl_map .erpl_title-h3 {
        color: $black;
    }
}

@media (min-width: map-get($map: $grid-breakpoints, $key: sm)) {
    .erpl_map .erpl_title-h3 {
        color: $white;
    }
}

//──── Mapping zones ────────────────────────────────────────────────────────────────
.erpl_zone {
    background: rgba(0, 0, 0, 0) url("../../assets/img/map-world.png") no-repeat scroll;
    transform: scale(0.95);
    transition: transform 0.2s ease-out 0s;
    height: 155px;
}

.erpl_zone.asiapa {
    background-position: 50% -181px;
}

.erpl_zone.noamer {
    background-position: 50% -340px;
}

.erpl_zone.ceasia {
    background-position: 50% -1305px;
}

.erpl_zone.noeuno {
    background-position: 50% -1145px;
}

.erpl_zone.soamer {
    background-position: 50% -824px;
}

.erpl_zone.memiea {
    background-position: 50% -985px;
}

.erpl_zone.subafr {
    background-position: 50% -1465px;
}

.erpl_zone.euruni {
    background-position: 50% -656px;
}

.erpl_zone.russia {
    background-position: 50% -1306px;
}

//──── MAP WORLD BACKGROUND GREY ──────────────────────────────────────────────────────────────────
.erpl_map.bg-light-gradient {
    background: $gradient2;
    margin-top: 0px;
}

.erpl_map.bg-light-gradient .erpl_title-h3 {
    color: $black;
}