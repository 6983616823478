
//──── ERPL MAP Europe ───────────────────────────────────────────────────────────────────
.erpl_map-europe {
	position: relative;
	overflow: hidden;

	&[data-theme="blue"] {
		path {
			fill: $primary;
			stroke: #d1d2d4;
			stroke-width: 1px;
			transition: 0.3s;
			transform-origin: center;

			
		}

		path[data-selectable="true"]:hover,
		path[data-selectable="true"].hover,
		path[data-selectable="true"].active,
		.more-area [data-selectable="true"].active path,
		.more-area [data-selectable="true"].hover path{
			fill: $dark-primary;
			cursor: pointer;
		}

		&[data-more-area-overmap="true"] .more-area {
			background: rgba(255, 255, 255, .75)
		}
	}


	&[data-theme="blue-reverse"] {
		background: $gradient1;
	}

	&[data-theme="blue-reverse"], 
	&[data-theme="white"] {
		path {
			fill: $white;
			stroke: #d1d2d4;
			stroke-width: 1px;
			transition: 0.3s;
			transform-origin: center
		}

		path[data-selectable="true"]:hover,
		path[data-selectable="true"].hover,
		path[data-selectable="true"].active,
		.more-area [data-selectable="true"].active path,
		.more-area [data-selectable="true"].hover path{
			fill: $primary;
		}

		// &[data-more-area-overmap="true"] .more-area {
		// 	background: rgba(35, 63, 100, .75)
		// }		
	}

	&[data-more-area-position="right"] {
		.map__image {
			display: flex;
			flex-direction: row-reverse
		}

		.more-area {
			display: flex;
			flex-direction: column
		}

		&[data-more-area-overmap="true"] .more-area {
			right: 0;
		}
	}

	&[data-more-area-position="left"] {
		.map__image {
			display: flex;
			flex-direction: row
		}

		.more-area {
			display: flex;
			flex-direction: column
		}

		&[data-more-area-overmap="true"] .more-area {
			left: 0;
		}
	}

	&[data-more-area-position="top"]{
		.map__image {
			display: flex;
			flex-direction: column;
			align-items: center
		}

		.more-area {
			display: flex;
			justify-content: center
		}

		&[data-more-area-overmap="true"] .more-area {
			top: 0;
			left: 0;
			right: 0;
			text-align: center
		}
	}

	&[data-more-area-position="bottom"] {
		.map__image {
			display: flex;
			flex-direction: column-reverse;
			align-items: center
		}

		.more-area {
			display: flex;
			justify-content: center
		}

		&[data-more-area-overmap="true"] .more-area {
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center
		}
	}

	&[data-more-area-overmap="true"] .more-area {
		position: absolute;
	} 
 
	&[data-outside-ue="hidden"] [data-selectable="false"]  {
		display: none;
	}

	&[data-outside-ue="disabled"] [data-selectable="false"] {
		fill: $gray-500;
		cursor: initial;
		opacity: .15;
		stroke: $white
	}

	#euroMapSelect {
		width: 100%;
		// min-height: 500px;
	} 

	[data-selectable="true"] {
		cursor: pointer;
	}

	// BREXIT
	path#GB {
		display: none;
	}
	

}