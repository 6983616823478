.erpl_admin {
	.erpl_admin-online-users {
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: inline-flex;
		}
	}

	.erpl_admin-user-avatar {
		background: $gray-300;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		cursor: pointer;
		font-size: 1rem;
		transition:.2s;

		&[data-size="small"] {
			height: 30px;
			width: 30px;
			font-size: $small-font-size;
		}

		&:hover {
			filter: saturate(1.75)
		}
	}

	.erpl_admin-user-chip {
		background: $gray-300;
		height: 8px;
		width: 8px;
		display: inline-block;
	}
}

